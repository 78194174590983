<template>
  <div>
    <div class="user-info">
      <!-- 个人资料 -->
      <div class="user-info-name" v-show="baseInfo.content">
        <div class="user-info-name-left">
          <div class="user-info-name-photo">
            <img v-if="currentUser.avatar" v-lazy="currentUser.avatar" alt=""/>
          </div>
          <div class="user-info-name-list">
            <ul>
              <li v-if="userData.weight>100">
                <Isvg icon-class="platform-certification"/>
                平台推荐
              </li>
              <li>
                <Isvg icon-class="realname-certification"/>
                实名认证 &nbsp; &nbsp;
                <span v-if="userData.authinfo.real_name.status==9">已完成</span>
                <span v-else>未完成</span>
              </li>
              <li>
                <Isvg icon-class="enterprise-certification"/>
                企业认证 &nbsp; &nbsp;
                <span v-if="userData.authinfo.company.status==9">已完成</span>
                <span v-else>未完成</span>
              </li>
              <li>
                <Isvg icon-class="email-certification"/>
                邮箱认证 &nbsp; &nbsp;
                <span v-if="userData.authinfo.email.status==9">已完成</span>
                <span v-else>未完成</span>
              </li>
              <li>
                <Isvg icon-class="mobile-certification"/>
                手机认证 &nbsp; &nbsp;
                <span v-if="userData.authinfo.mobile.status==9">已完成</span>
                <span v-else>未完成</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="user-info-name-right">
          <!-- 修改个人资料部分 -->
          <div class="user-info-you">
            <div class="user-info-you-name">
              <h2>{{ currentUser.name }}</h2>
              <p style="line-height:20px">{{ userData.slogan }}</p>
            </div>
            <div class="user-info-update" @click="toEdit('base')">
              <div class="user-info-update-img">
                <el-link type="primary" icon="el-icon-edit"/>
              </div>
              <span>修改个人资料</span>
            </div>
          </div>
          <!-- 技能部分 -->
          <div class="user-info-task">
            <div class="user-info-tasks">
              <strong>技能：</strong>
              <ul class="user-info-tasks-right">
                <li style="font-size: 14px" v-for="(skillItem, skillIndex) in userData.skills"
                    :key="skillIndex">
                  {{ skillItem.name }}
                  <span v-if="userData.skills.length>(skillIndex+1)">/</span>
                </li>
              </ul>
            </div>
            <!--  -->
            <div class="user-task-center">
              <div class="user-task-center-left">
                <ul>
                  <li>
                    <strong>报酬：</strong>
                    <span>¥ {{ userData.hourly_rate }} / 小时</span>
                  </li>
                  <li class="user-task-center-address">
                    <strong>地区：</strong>
                    {{
                      checkedAddressName(userData.province_id, userData.city_id, userData.county_id)
                    }}
                  </li>
                  <li class="system">
                    <strong>评价：</strong>
                    <span>
											<el-rate v-model="userData.review_score" disabled text-color="#ff9900"/>
										</span>
                    <span class="f-14">（ {{ userData.review_count }} 个评论 ）</span>
                  </li>
                </ul>
              </div>
              <div class="user-task-center-right">
                <ul>
                  <li>
                    <strong>加入平台时间：</strong>
                    <span>{{ userData.settled_days }} 天</span>
                  </li>
                  <li v-show="false">
                    <strong>平均响应时间：</strong>
                    <span>3小时</span>
                  </li>
                  <li>
                    <strong>已完成的工作：</strong>
                    <span>{{
                        userData.finshi_project_num > 0 ? userData.finshi_project_num + '单' : '0 项'
                      }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="user-task-font">
            <p>{{ userData.intro }}</p>
          </div>
        </div>
      </div>

      <!-- 完善个人资料 -->
      <div class="user-info-name-border" v-show="baseInfo.edit">
        <div class="user-info-border-center" v-loading="baseloading">
          <div class="user-info-name-border-left">
            <div class="user-info-border-photo">
              <img :src="avatar" alt=""/>
              <el-upload :action="postUrl" :headers="headers" accept="image/gif,image/jpeg,image/png"
                         :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :show-file-list="false">
                <button>上传图片</button>
              </el-upload>
            </div>
            <!-- 认证组件 -->
            <PersonAtt :authInfo="userData.authinfo" class="personAtt"/>
          </div>
          <div class="user-info-name-border-right">
            <el-form :model="baseForm" :rules="rules" size="small" label-width="50px" label-position="left"
                     ref="baseForm" class="demo-baseForm">
              <el-form-item label="昵称" prop="nick_name">
                <el-input maxlength="11" v-model="baseForm.nick_name" placeholder="限11个字符" show-word-limit clearable/>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                <el-radio-group v-model="baseForm.sex">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="标语" prop="slogan">
                <el-input v-model="baseForm.slogan" placeholder="请输入自己的标语，至少包含一个技能。" maxlength="20" show-word-limit clearable/>
              </el-form-item>
              <div class="user-info-name-border-right-address">
                <el-form-item label="地区" prop="address">
                  <Region ref="RegionVisble" @reginCallBack="reginBack" />
                </el-form-item>
              </div>
              <el-form-item label-width="100px" label="每小时收费">
                <span class="hourly-text" >为您的个人资料输入每小时费率以对该项目投标，这不会影响您对特定项目的投标。</span>
              </el-form-item>
              <el-form-item prop="hourly_rate">
                <el-input type="number" v-model="baseForm.hourly_rate" style="width:215px" clearable/>
                <strong class="hourly-text ml-10">元/小时</strong>
              </el-form-item>
              <el-form-item label="简介" prop="intro">
                <el-input
                    type="textarea"
                    placeholder="请输入您的自我介绍，至少包含一个技能。"
                    v-model.trim="baseForm.intro"
                    maxlength="300"
                    show-word-limit
                    :autosize="{ minRows: 2 }"
                />
              </el-form-item>
              <!-- 技能组件 -->
              <el-form-item label="技能" prop="skills" class="task-work">
                <Skills
                    :styleAttrs="{ width: '570px' }"
                    :checked_skill_cat_id="userData.skill_cat_id"
                    :checked_skills="userData.skills"
                    @skillCallBack="skillCallBack"
                />
              </el-form-item>

              <!-- 技能组件 end-->
              <div class="user-info-name-border-right-checked" v-show="false">
                <el-checkbox label="请先同意" />
                <a href="javascript:void(0)" style="color:#00a2e6;font-size:12px">《八爪用户协议》</a>
              </div>
              <div class="user-addeducation-button">
                <el-button size="small" :loading="loadingBtn.base" @click="toSubmit('base')" class="button-primary">保存
                </el-button>
                <el-button size="small" @click="toCancel('base')" class="button-close">取消</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>

      <!-- 职业经验 -->
      <div class="user-info-experience">
        <div class="user-info-header">
          <div class="user-info-header-center">
            <div class="user-info-header-left">
              <strong>职业经验</strong>
              <span>( 可见等级 : {{ userData.professional_open_level_name }} )</span>
            </div>
            <div class="user-info-header-right">
              <b @click="toEdit('experience', true)">
                <i class="el-icon-plus"></i>
                添加经历</b>
              <el-divider direction="vertical"/>
              <el-dropdown class="view-level" @command="changeOpenLevel">
                <span class="el-dropdown-link">
                  <i class="el-icon-view"></i>
                  可见等级
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="experience-1">全公开</el-dropdown-item>
                  <el-dropdown-item command="experience-2">私密(投标可见)</el-dropdown-item>
                  <el-dropdown-item command="experience-3">有偿可见</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="user-info-center-font" v-show="experienceInfo.content">
          <div class="user-info-center" v-loading="exploading" v-for="(expItem, expIndex) in userData.professionals"
               :key="expIndex">
            <div class="user-info-center-header">
              <div class="user-info-center-heder-left">
                <strong>{{ expItem.position_name }}</strong>
              </div>
              <div class="user-info-center-heder-right">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">...</span>
                  <el-dropdown-menu slot="dropdown" class="user-info-center-heder-right-menu">
                    <el-dropdown-item @click.native="toSave('experience', expItem)">编辑</el-dropdown-item>
                    <el-dropdown-item @click.native="toDelete('experience', expItem)">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="user-info-center-container">
              <strong>公司名称：{{ expItem.company_name }}</strong>
              <span>( {{ expItem.start_time }} - {{
                  expItem.end_time == '' ? '至今' : expItem.end_time
                }} ) ( {{ expItem.year_count }} 年)</span>
            </div>
            <div class="user-info-center-bottom">
              <p>工作内容：{{ expItem.memo }}</p>
            </div>
          </div>
        </div>
        <div class="user-info-center-addProject" v-show="experienceInfo.empty">
          <div class="user-info-center-addProject-center">
            <img src="@/assets/banner/项目不存在.png" alt=""/>
            <span>请添加职业经历</span>
          </div>
        </div>
        <!-- 职业经验输入框 -->
        <div class="user-info-experience-border" v-show="experienceInfo.edit">
          <el-form :model="workForm" :rules="workRules" label-position='right' label-width="80px">
            <el-form-item label="职称" prop="position_name">
              <el-input style="width:300px" v-model="workForm.position_name" placeholder="输入您的职位或头衔"/>
            </el-form-item>
            <el-form-item prop="company_name" label="公司全称">
              <el-input v-model="workForm.company_name" placeholder="请输入公司全称或者简称" style="width:500px"/>
            </el-form-item>

            <div class="timeEditer">
              <el-form-item prop="start_time" label="在职时间">
                <el-date-picker
                    v-model="workForm.start_time"
                    type="month"
                    value-format="yyyy-MM"
                    placeholder="选择日期"/>
              </el-form-item>
              <el-form-item prop="end_time" label="至" label-width="40px">
                <el-date-picker
                    v-model="workForm.end_time"
                    type="month"
                    value-format="yyyy-MM"
                    :disabled="workForm.is_current==1"
                    placeholder="选择日期"/>
              </el-form-item>
              <div class="checkInDiv">
                <el-checkbox v-model="workForm.is_current" @change="changeIsCurrentWork"/>
                <span style="font-size: 12px;color: #575757">我目前在职</span>
              </div>

            </div>

            <el-form-item label="工作内容" prop="memo">
              <el-input type="textarea" placeholder="请输入工作内容" v-model="workForm.memo" maxlength="100" show-word-limit
                        :autosize="{ minRows: 2 }"/>
            </el-form-item>
          </el-form>
          <div class="user-addeducation-button">
            <el-button size="mini" :loading="loadingBtn.experience" @click="toSubmit('experience')"
                       class="button-primary">保存
            </el-button>
            <el-button size="mini" @click="toCancel('experience')" class="button-close">取消</el-button>
          </div>
        </div>
      </div>

      <!-- 教育经历 -->
      <div class="user-info-education">
        <div class="user-info-header">
          <div class="user-info-header-center">
            <div class="user-info-header-left">
              <strong>教育经历</strong>
              <span>( 可见等级 : {{ userData.education_open_level_name }} )</span>
            </div>
            <div class="user-info-header-right">
              <b @click="toEdit('education', true)"> <i class="el-icon-plus"></i>
                添加经历</b>
              <el-divider direction="vertical"/>
              <el-dropdown class="view-level" @command="changeOpenLevel">
                <span class="el-dropdown-link">
                  <i class="el-icon-view"></i>
                  可见等级
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="education-1">全公开</el-dropdown-item>
                  <el-dropdown-item command="education-2">私密(投标可见)</el-dropdown-item>
                  <el-dropdown-item command="education-3">有偿可见</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="user-info-education-center">
          <!-- 教育经历输入框 -->
          <div class="user-info-addeducations" v-show="educationInfo.edit">
            <el-form :model="educationForm" label-position="right" label-width="80px" ref="educationForm"
                     :rules="eduRules">
              <el-form-item label="学校名称" prop="school_name">
                <el-input id="school" style="width: 530px" v-model="educationForm.school_name" placeholder="输入学校全称"/>
              </el-form-item>
              <el-form-item label="学历学位" prop="major_level">
                <el-select v-model="educationForm.major_level" placeholder="请选择您的学历学位">
                  <el-option v-for="(item, idkey) in majorLevelArray" :key="idkey" :label="item"
                             :value="idkey"/>
                </el-select>
              </el-form-item>
              <el-form-item label="院系专业" prop="school_name">
                <el-input id="major" v-model="educationForm.major_name" placeholder="输入院校专业"/>
              </el-form-item>
              <div class="timeEditer">
                <el-form-item label="在校时间" prop="start_time">
                  <el-date-picker
                      v-model="educationForm.start_time"
                      type="month"
                      value-format="yyyy-MM"
                      placeholder="选择日期"/>
                </el-form-item>
                <el-form-item prop="end_time" label="至" label-width="40px">
                  <el-date-picker
                      v-model="educationForm.end_time"
                      type="month"
                      value-format="yyyy-MM"
                      :disabled="educationForm.is_current==1"
                      placeholder="选择日期"/>
                </el-form-item>
                <div class="checkInDiv">
                  <el-checkbox v-model="educationForm.is_current" @change="changeIsCurrentEdu"/>
                  <span style="font-size: 12px;color: #575757">我目前在读</span>
                </div>
              </div>
              <div class="user-addeducation-button">
                <el-button size="mini" :loading="loadingBtn.education" @click="toSubmit('education')"
                           class="button-primary">保存
                </el-button>
                <el-button size="mini" @click="toCancel('education')" class="button-close">取消</el-button>
              </div>
            </el-form>
          </div>

          <div v-show="educationInfo.content" v-loading="eduloading">
            <div class="user-info-education-font" v-for="(eduItem, eduIndex) in userData.education" :key="eduIndex">
              <div class="user-info-center-header">
                <div class="user-info-center-heder-left">
                  <strong>{{ eduItem.major_name }}</strong>
                </div>
                <div class="user-info-center-heder-right">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">...</span>
                    <el-dropdown-menu slot="dropdown" class="user-info-center-heder-right-menu">
                      <el-dropdown-item @click.native="toSave('education', eduItem)">编辑</el-dropdown-item>
                      <el-dropdown-item @click.native="toDelete('education', eduItem)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="user-info-education-bottom">
                <strong>学历：{{ eduItem.major_level_name }}</strong>
                <br>
                <br>
                <strong>学校名称：{{ eduItem.school_name }}</strong>
                <span>( {{ eduItem.start_time }} - {{
                    eduItem.end_time == '' ? '至今' : eduItem.end_time
                  }} ) ( {{ eduItem.year_count }} 年)</span>
              </div>
            </div>
          </div>

          <div class="user-info-center-addProject" v-show="educationInfo.empty">
            <div class="user-info-center-addProject-center">
              <img src="@/assets/banner/项目不存在.png" alt=""/>
              <span>请添加教育经历</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 资格和荣誉 -->
      <div class="user-info-honor">
        <div class="user-info-header">
          <div class="user-info-header-center">
            <div class="user-info-header-left">
              <strong>资格和荣誉</strong>
              <span>( 可见等级 : {{ userData.qualification_open_level_name }} )</span>
            </div>
            <div class="user-info-header-right">
              <b @click="toEdit('honor', true )"><i class="el-icon-plus"></i> 添加经历</b>
              <el-divider direction="vertical"/>
              <el-dropdown class="view-level" @command="changeOpenLevel">
                <span class="el-dropdown-link">
                  <i class="el-icon-view"></i>
                  可见等级
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="honor-1">全公开</el-dropdown-item>
                  <el-dropdown-item command="honor-2">私密(投标可见)</el-dropdown-item>
                  <el-dropdown-item command="honor-3">有偿可见</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <div class="user-info-honor-center">
          <div v-show="honorInfo.content" v-loading="honorloading">
            <div class="user-info-honor-font" v-for="(honorItem, honorIndex) in userData.honors" :key="honorIndex">
              <div class="user-info-center-header">
                <div class="user-info-center-heder-left">
                  <strong>{{ honorItem.qul_name }}</strong>
                </div>
                <div class="user-info-center-heder-right">
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">...</span>
                    <el-dropdown-menu slot="dropdown" class="user-info-center-heder-right-menu">
                      <el-dropdown-item @click.native="toSave('honor', honorItem)">编辑</el-dropdown-item>
                      <el-dropdown-item @click.native="toDelete('honor', honorItem)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="user-info-center-bottom">
                <strong>授予机构：{{ honorItem.award_by }}</strong>
                <span>颁发时间：{{ honorItem.get_year }}</span>
              </div>
            </div>
          </div>

          <div class="user-info-center-addProject" v-show="honorInfo.empty">
            <div class="user-info-center-addProject-center">
              <img src="@/assets/banner/项目不存在.png" alt=""/>
              <span>请添加资格荣誉</span>
            </div>
          </div>
        </div>
        <!-- 资格荣誉输入框 -->
        <div class="user-honor-border" v-show="honorInfo.edit">
          <el-form :model="honorForm" label-position="right" label-width="90px">
            <el-form-item label="证书/名誉" prop="qul_name">
              <el-input id="honor" v-model="honorForm.qul_name" placeholder="请输入证书/名誉名称"/>
            </el-form-item>

            <el-form-item label="颁发机构" prop="award_by">
              <el-input id="honor" v-model="honorForm.award_by" placeholder="请输入颁发的机构"/>
            </el-form-item>

            <el-form-item label="取得年份" prop="get_year">
              <el-date-picker v-model="honorForm.get_year" type="month" value-format="yyyy-MM"
                              placeholder="年/月"/>
            </el-form-item>
          </el-form>

          <div class="user-addeducation-button">
            <el-button :loading="loadingBtn.honor" size="mini" @click="toSubmit('honor')" class="button-primary">保存
            </el-button>
            <el-button size="mini" @click="toCancel('honor')" class="button-close">取消</el-button>
          </div>
          <div class="mb-40"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonAtt from '@/components/PersonAtt';
import Region from '@/components/Region/Region';
import Skills from '@/components/Skills';
import checkedAddressName from "@/utils/checkedAddressName";

import {
  getUserProfile,
  getUserProfileKey,
  saveProfile,
  saveProfessionals,
  saveProeducation,
  saveHonor,
  deleteProfessionals,
  deleteProeducation,
  deleteHonor, changeUserOpenLevel
} from '@/api/user';

export default {
  components: {
    PersonAtt,
    Region,
    Skills
  },
  data() {
    var checkSlogan = (rule, value, callback) => {
      if (value > 1000) {
        return callback(new Error('您超过了最高1000/小时'))
      } else {
        callback()
      }
    };
    var validatorStartTime = (rule, value, callback) => {
      const currentDate = new Date();
      const selectDate = new Date(value);
      const currentYearMonth = currentDate.getFullYear() + currentDate.getMonth()
      const checkYearMonth = selectDate.getFullYear() + selectDate.getMonth()
      if (checkYearMonth > currentYearMonth) {
        callback(new Error('日期不能大于当前时间'));
      } else {
        callback();
      }
    };
    var validatorEndTime = (rule, value, callback) => {
      const currentDate = new Date();
      const selectDate = new Date(value);
      const currentYearMonth = currentDate.getFullYear() + currentDate.getMonth()
      const checkYearMonth = selectDate.getFullYear() + selectDate.getMonth()
      if (checkYearMonth > currentYearMonth) {
        callback(new Error('日期不能大于当前时间'));
      } else {
        callback();
      }
    };
    return {
      checkedAddressName,
      // 图片上传部分
      postUrl: '/api/upload/avatar',
      headers: {
        Authorization: `Bearer ${this.$store.getters["useUserStore/getToken"]}`
      },
      avatar: '',
      currentUser: null,

      baseloading: false,
      exploading: false,
      eduloading: false,
      honorloading: false,

      eduEndTimeDisabled: false,
      workEndTimeDisabled: false,

      baseInfo: {
        content: true,
        edit: false
      },
      experienceInfo: {
        content: false,
        edit: false,
        empty: true
      },
      educationInfo: {
        content: false,
        edit: false,
        empty: true
      },
      honorInfo: {
        content: false,
        edit: false,
        empty: true
      },

      userData: {
        profile: {},
        proeducation: {},
        professionals: {},
        honor: {},
        certifications: {},
        authinfo: {
          company: {},
          email: {},
          real_name: {},
          mobile: {}
        }
      },
      value: 5,
      skills: [], //展示的数组
      recommand_skills: [],
      loadingBtn: {
        base: false,
        experience: false,
        education: false,
        honor: false
      },
      //基本信息
      baseForm: {
        avatar_version: '',
        nick_name: '', //昵称
        sex: '', //性别
        slogan: '', //标语
        address: '', //请设置工作地址
        hourly_rate: '', //每小时收费
        intro: '', //个人介绍
        skills: [], //技能
        checked: '' //协议
      },
      rules: {
        // 昵称
        nick_name: [
          {
            message: '请输入您的昵称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 11,
            message: '昵称长度请设置在1~11个字符',
            trigger: 'blur'
          },
        ],
        //性别
        gender: [
          {
            message: '请选择您的性别',
            trigger: 'blur'
          }
        ],
        //标语
        slogan: [
          {
            message: '至少包含1个技能',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 100,
            message: '长度限制在100个字符',
            trigger: 'blur'
          }
        ],
        //地区
        address: [
          {
            message: '请输入您的地区',
            trigger: 'blur'
          }
        ],
        //每小时收费
        hourly_rate: [
          {
            message: '请输入您每小时收费',
            trigger: 'blur'
          },
          {
            validator: checkSlogan, trigger: 'blur'
          },
        ],
        //个人介绍
        into: [
          {
            message: '请输入您的自我介绍，至少包含一个技能',
            trigger: 'blur'
          }
        ],
        //技能分类
        checked: [
          {
            message: '请同意协议',
            trigger: 'blur'
          }
        ],
        // 职位
        position_name: [
          {
            max: 100,
            message: "您输入的职位或头衔超过了100字符",
            trigger: 'blur'
          }
        ],
        //公司全称
        company_name: [
          {
            max: 50,
            message: "您输入的公司名称超过了50个字符"
          }
        ],
        //在职时间

        /* company_name */
      },
      workRules: {
        start_time: [
          {validator: validatorStartTime, trigger: 'change'}
        ],
        end_time: [
          {validator: validatorEndTime, trigger: 'change'}
        ]
      },
      eduRules: {
        start_time: [
          {validator: validatorStartTime, trigger: 'change'}
        ],
        end_time: [
          {validator: validatorEndTime, trigger: 'change'}
        ]
      },
      // 职业经理人表单
      workForm: {
        position_name: '', //职位
        company_name: '', //公司全称
        start_time: '',
        end_time: '',
        is_current: false, //多选框
        memo: '' //工作内容
      },
      majorLevelArray: ['无学历', '初中', '高中/中专', '大专', '本科', '硕士', '博士', '博士后', '其他'],
      //教育经历
      educationForm: {
        school_name: '', //学校
        major_name: '', //专业名称
        major_level: '', //学历选择
        start_time: '',
        end_time: '',
        is_current: false //多选框
      },
      //资格和荣誉
      honorForm: {
        qul_name: '', //证书荣誉全程
        award_by: '', //颁发的机构
        get_year: '' //取得年份
      }
    };
  },
  created() {
    this.loadUserData();
    this.currentUser = this.$store.getters["useUserStore/getUserInfo"]
  },
  methods: {
    async loadUserData() {
      const result = await getUserProfile({});
      if (result.statusCode == 200) {
        this.userData = result.data;
        this.userData.review_score = Number(this.userData.review_score)
        if (this.userData.education.length > 0) {
          this.educationInfo.content = true;
          this.educationInfo.empty = false;
          this.userData.education.map( item => {
            if(item.is_current){
              item.end_time = ''
            }
          })
        }

        if (this.userData.professionals.length > 0) {
          this.experienceInfo.content = true;
          this.experienceInfo.empty = false;
          this.userData.professionals.map( item => {
            if(item.is_current){
              item.end_time = ''
            }
          })
        }

        if (this.userData.honors.length > 0) {
          this.honorInfo.content = true;
          this.honorInfo.empty = false;
        }
      }
    },
    handleAvatarSuccess(res, file) {
      this.avatar = res.data.avatar;
      this.currentUser.avatar = res.data.avatar
      this.baseForm.avatar_version = res.data.version;
      this.$store.dispatch('useUserStore/setUserInfo', this.currentUser)
    },
    beforeAvatarUpload(file) {
      const isJPGOrGIF = ['image/jpeg', 'image/gif', 'image/png'].indexOf(file.type) >= 0;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPGOrGIF) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片只能是 JPG 格式 GIF格式!'
        });
      }
      if (!isLt2M) {
        this.$notify({
          type: 'error',
          title: '通知',
          message: '上传头像图片大小不能超过 2MB!'
        });
      }
      return isJPGOrGIF && isLt2M;
    },
    //
    toEdit(type, isAdd = false) {
      switch (type) {
        case 'base':
          this.baseInfo.content = false;
          this.avatar = this.currentUser.avatar;
          this.baseForm.avatar_version = this.currentUser.avatar_version;
          this.baseForm.nick_name = this.currentUser.name;
          this.baseForm.slogan = this.userData.slogan;
          this.baseForm.intro = this.userData.intro;
          this.baseForm.sex = this.userData.sex.toString();
          this.baseForm.hourly_rate = this.userData.hourly_rate;
          this.$refs.RegionVisble.checkedVal(this.userData.county_id);
          this.baseForm.skill_cat_id = this.userData.skill_cat_id;
          this.baseForm.skill_ids = this.userData.skill_ids;
          this.baseForm.region = [this.userData.province_id, this.userData.city_id, this.userData.county_id];
          this.baseInfo.edit = true;
          break;
        case 'experience':
          if (isAdd) {
            this.workForm = {}
          }
          this.experienceInfo.content = false;
          this.experienceInfo.empty = false;
          this.experienceInfo.edit = true;
          break;
        case 'education':
          if (isAdd) {
            this.educationForm = {}
          }
          this.educationInfo.content = false;
          this.educationInfo.empty = false;
          this.educationInfo.edit = true;
          break;
        case 'honor':
          if (isAdd) {
            this.honorForm = {}
          }
          this.honorInfo.content = false;
          this.honorInfo.empty = false;
          this.honorInfo.edit = true;
          break;
      }
    },

    toSubmit(type) {
      switch (type) {
        case 'base':
          this.$refs.baseForm.validate((valid) => {
                if (valid) {
                  this.submit(type, this.baseForm);
                } else {
                  return false;
                }
              }
          )
          break;
        case 'experience':
          this.submit(type, this.workForm);
          break;
        case 'education':
          this.submit(type, this.educationForm);
          break;
        case 'honor':
          this.submit(type, this.honorForm);
          break;
      }
    },

    toCancel(type) {
      switch (type) {
        case 'base':
          this.baseInfo.content = true;
          this.baseInfo.edit = false;
          break;
        case 'experience':
          if (this.userData.professionals.length == 0) {
            this.experienceInfo.empty = true;
          } else {
            this.experienceInfo.content = true;
            this.workForm = {};
          }
          this.experienceInfo.edit = false;
          break;
        case 'education':
          if (this.userData.education.length == 0) {
            this.educationInfo.empty = true;
          } else {
            this.educationInfo.content = true;
            this.educationForm = {};
          }
          this.educationInfo.edit = false;
          break;
        case 'honor':
          if (this.userData.honors.length == 0) {
            this.honorInfo.empty = true;
          } else {
            this.honorInfo.content = true;
            this.honorForm = {};
          }
          this.honorInfo.edit = false;
          break;
      }
    },

    toSave(type, item) {
      switch (type) {
        case 'base':
          break;
        case 'experience':
          this.workForm = item;
          this.$set(this.workForm, 'start_time', item.start_time)
          this.$set(this.workForm, 'end_time', item.end_time)
          this.workForm.is_current = this.workForm.is_current > 0 ? true : false;
          this.toEdit(type);
          break;
        case 'education':
          this.educationForm = item;
          this.$set(this.educationForm, 'start_time', item.start_time)
          this.$set(this.educationForm, 'end_time', item.end_time)
          this.educationForm.is_current = this.educationForm.is_current > 0 ? true : false;
          this.toEdit(type);
          break;
        case 'honor':
          this.honorForm = item;
          this.toEdit(type);
          break;
      }
    },

    toDelete(type, item) {
      this.$confirm('此操作将删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.delete(type, item);
          })
          .catch(() => {
            //2
          });
    },

    async submit(type, data) {
      this.loadingBtn[type] = true;
      switch (type) {
        case 'base':
          this.baseloading = true
          const result = await saveProfile(data);
          if (result.statusCode == 200) {
            this.currentUser.avatar = this.avatar == '' ? this.currentUser.avatar : this.avatar;
            this.currentUser.name = this.baseForm.nick_name == '' ? this.currentUser.nick_name : this.baseForm.nick_name;
            this.currentUser.slogan = this.baseForm.slogan == '' ? this.currentUser.slogan : this.baseForm.slogan;
            this.currentUser.intro = this.baseForm.intro == '' ? this.currentUser.intro : this.baseForm.intro;
            this.$store.dispatch('useUserStore/setUserInfo', {user: this.currentUser})
            this.$notify({
              type: 'success',
              title: '通知',
              message: '修改成功'
            });

            this.baseInfo.content = true;
            this.baseInfo.edit = false;
            this.toLoadThisKey(type);
            this.loadingBtn[type] = false;
            this.baseloading = false
          } else {
            this.$notify({
              type: 'error',
              title: '通知',
              message: result.message
            });
            this.loadingBtn[type] = false;
            this.baseloading = false
          }
          break;
        case 'experience':
          this.exploading = true
          data.is_current = data.is_current ? 1 : 0;
          if (data.is_current == 1) {// 在职中去掉结束时间
            data.end_time = null
          }
          const result1 = await saveProfessionals(data);
          if (result1.statusCode == 200) {
            this.$notify({
              type: 'success',
              title: '通知',
              message: '修改成功'
            });
            this.toLoadThisKey(type);
            this.loadingBtn[type] = false;
            this.exploading = false
          } else {
            this.$notify({
              type: 'error',
              title: '通知',
              message: result1.message
            });
            this.loadingBtn[type] = false;
            this.exploading = false
          }
          break;
        case 'education':
          this.eduloading = true
          data.is_current = data.is_current ? 1 : 0;
          if (data.is_current == 1) {// 在读书中去掉结束时间
            data.end_time = null
          }
          const result2 = await saveProeducation(data);
          if (result2.statusCode == 200) {
            this.$notify({
              type: 'success',
              title: '通知',
              message: '修改成功'
            });
            this.toLoadThisKey(type);
            this.loadingBtn[type] = false;
            this.eduloading = false
          } else {
            this.$notify({
              type: 'error',
              title: '通知',
              message: result2.message
            });
            this.loadingBtn[type] = false;
            this.eduloading = false
          }
          break;
        case 'honor':
          this.honorloading = true
          const result3 = await saveHonor(data);
          if (result3.statusCode == 200) {
            this.$notify({
              type: 'success',
              title: '通知',
              message: '修改成功'
            });
            this.toLoadThisKey(type);
            this.loadingBtn[type] = false;
            this.honorloading = false
          } else {
            this.$notify({
              type: 'error',
              title: '通知',
              message: result3.message
            });
            this.loadingBtn[type] = false;
            this.honorloading = false
          }
          break;
      }
    },

    async delete(type, data) {
      switch (type) {
        case 'experience':
          this.exploading = true
          const res = await deleteProfessionals({id: data.id});
          if (res.statusCode == 200) {
            this.setTimeOutStyle();
            this.userData.professionals.splice(data, 1);
            this.exploading = false
          } else {
            this.exploading = false
          }
          break;
        case 'education':
          this.eduloading = true
          const res1 = await deleteProeducation({id: data.id});
          if (res1.statusCode == 200) {
            this.setTimeOutStyle();
            this.userData.education.splice(data, 1);
            this.eduloading = false
          } else {
            this.eduloading = false
          }
          break;
        case 'honor':
          this.honorloading = true
          const res2 = await deleteHonor({id: data.id});
          if (res2.statusCode == 200) {
            this.setTimeOutStyle();
            this.userData.honors.splice(data, 1);
            this.honorloading = false
          } else {
            this.honorloading = false
          }
          break;
      }
    },

    async toLoadThisKey(type) {
      const res = await getUserProfileKey(
          {
            key: type
          }
      );
      if (res.statusCode == 200) {
        switch (type) {
          case 'base':
            res.data.review_score = Number(res.data.review_score)
            this.userData.avatar = res.data.avatar;
            this.userData.name = res.data.name;
            this.userData.sex = res.data.sex;
            this.userData.slogan = res.data.slogan;
            this.userData.intro = res.data.intro;
            this.userData.hourly_rate = res.data.hourly_rate;
            this.userData.skills = res.data.skills;
            this.userData.skill_cat_id = res.data.skill_cat_id;

            this.baseInfo.content = true;
            this.baseInfo.edit = false;
            break;
          case 'experience':
            this.userData.professionals = res.data;
            this.experienceInfo.content = true;
            this.experienceInfo.edit = false;
            break;
          case 'education':
            this.userData.education = res.data;
            this.educationInfo.content = true;
            this.educationInfo.edit = false;
            break;
          case 'honor':
            this.userData.honors = res.data;
            this.honorInfo.content = true;
            this.honorInfo.edit = false;
            break;
        }
        this.setTimeOutStyle();
      }
    },
    handleCommand(command) {
      //this.$message('click on item ' + command);
    },
    // 返回地址信息
    reginBack(region) {
      this.baseForm.region = region;
    },
    // 延迟执行
    setTimeOutStyle() {
      var that = this;
      setTimeout(function () {
        that.loading = false;
      }, 1000);
    },
    timeProcess(data) {
      return ''; //new Date(data+' 01 00:00:00');
    },
    handleTimestamp(val) {
      console.log(val);
    },
    skillCallBack(data) {
      this.baseForm.skill_ids = data.skill_ids;
      this.baseForm.skill_cat_id = data.skill_cat_id;
    },
    changeIsCurrentEdu(val) {
      if (val) {
        this.eduEndTimeDisabled = true
      } else {
        this.eduEndTimeDisabled = false
      }
    },
    changeIsCurrentWork(val) {
      if (val) {
        this.workEndTimeDisabled = true
      } else {
        this.workEndTimeDisabled = false
      }
    },
    async changeOpenLevel(val) {
      let openLevel = val.split('-')
      let data = {}
      let levels = {1: '全公开', 2: '私密', 3: '有偿可见'};
      let levelName = ''
      switch (openLevel[0]) {
        case 'experience':
          data.professional_open_level = openLevel[1]
          levelName = 'professional_open_level_name'
          break
        case 'education':
          data.education_open_level = openLevel[1]
          levelName = 'education_open_level_name'
          break
        case 'honor':
          data.qualification_open_level = openLevel[1]
          levelName = 'qualification_open_level_name'
          break;
      }

      const res = await changeUserOpenLevel(data)
      if (res.statusCode == 200) {
        this.$message.success('切换成功')
        this.userData[levelName] = levels[openLevel[1]]
      }
    }
  }
};
</script>

<style lang="less" scoped>
.user-info {
  width: 940px;

  //个人资料
  .user-info-name {
    width: 940px;
    max-height: 456px;
    background: #ffffff;
    display: flex;
    padding-bottom: 20px;

    .user-info-name-left {
      width: 200px;
      margin-left: 40px;
      margin-top: 40px;

      .user-info-name-photo {
        width: 200px;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .user-info-name-list {
        margin-top: 40px;

        li {
          display: flex;
          align-items: center;
          height: 16px;
          margin-bottom: 20px;
          font-size: 12px;
          color: #575757;

          svg {
            width: 14px;
            height: 14px;
            margin-right: 20px;
          }
        }
      }
    }

    .user-info-name-right {
      width: 620px;
      margin-left: 40px;
      margin-top: 40px;

      // 修改个人资料
      .user-info-you {
        width: 100%;
        min-height: 62px;
        border-bottom: 1px solid #e4e7ed;
        display: flex;
        justify-content: space-between;

        .user-info-you-name {
          width: 80%;

          h2 {
            font-size: 18px;
            margin-bottom: 12px;
          }

          p {
            color: #575757;
            font-size: 12px;
          }
        }

        .user-info-update {
          height: 18px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .user-info-update-img {
            width: 18px;
            height: 18px;
            text-align: center;
            display: inline-block;
          }

          span {
            margin-left: 10px;
            font-size: 14px;
            color: #00a2e6;
          }
        }
      }

      //技能部分
      .user-info-task {
        width: 100%;
        height: 182px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #e4e7ed;

        .user-info-tasks {
          margin-top: 20px;
          display: flex;
          height: 38px;

          strong {
            width: 50px;
            font-size: 14px;
          }

          ul {
            li {
              font-size: 12px;
              float: left;
              margin-bottom: 10px;

              span {
                margin: 0 9.6px;
              }
            }
          }
        }

        .user-task-center {
          width: 100%;
          height: 100px;
          display: flex;

          .user-task-center-left,
          .user-task-center-right {
            width: 295px;
            display: flex;

            li {
              font-size: 14px;
              margin-bottom: 22px;

              span {
                color: #575757;
              }
            }

            .user-task-center-address {
              display: flex;

              span {
                margin-right: 20px;
              }
            }
          }
        }
      }

      //自我介绍文字部分
      .user-task-font {
        margin-top: 15px;
        font-size: 12px;
        color: #575757;
        display: flex;
        flex-wrap: wrap;

        p {
          width: 620px;
          line-height: 24px;
          word-break: break-all;
        }
      }
    }
  }

  //完善个人资料
  .user-info-name-border {
    width: 940px;
    background: #fff;
    display: flex;

    .user-info-border-center {
      width: 860px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .user-info-name-border-left {
        width: 200px;

        .user-info-border-photo {
          width: 200px;
          text-align: center;

          img {
            width: 200px;
            height: 200px;
            margin-bottom: 20px;
            object-fit: cover;
          }

          button {
            width: 150px;
            height: 36px;
            background-color: #00a2e6;
            font-size: 14px;
            color: #fff;
          }
        }
      }

      .user-info-name-border-right {
        width: 620px;

        .hourly-text {
          font-size: 12px;
          color: #575757;
        }

        .skill-p {
          line-height: 1;
          margin-top: 10px;
          font-size: 14px;

          span {
            font-size: 12px;
            color: #575757;
            margin-right: 10px;
          }
        }

        .skill-select {
          width: 620px;
        }

        .user-info-name-border-right-checked {
          margin-top: 40px;
        }

        .user-addeducation-button {
          margin-bottom: 40px;
        }
      }
    }
  }

  //工作经验
  .user-info-experience {
    margin-top: 20px;
    width: 940px;
    background: #ffffff;
    padding-bottom: 10px;

    .user-info-center {
      width: 860px;
      margin: 0 auto;
      border-bottom: 1px solid #e4e7ed;
      padding-bottom: 40px;

      span {
        margin-left: 10px;
        color: #575757;
      }

      .user-info-center-header {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .user-info-center-container {
        margin-top: 20px;

        strong {
          font-size: 14px;
        }

        span {
          font-size: 12px;
        }
      }

      .user-info-center-bottom {
        margin-top: 20px;

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    & .user-info-center:last-of-type {
      border-bottom: none;
    }

    //没有工作经验
    .user-info-center-addProject {
      height: 346px;
      width: 100%;
      margin-top: 40px;

      .user-info-center-addProject-center {
        width: 300px;
        margin: 0 auto;
        text-align: center;

        img {
          width: 300px;
          height: 190px;
        }

        span {
          display: block;
          margin-top: 40px;
          font-size: 18px;
          color: #575757;
        }
      }
    }

    //工作经验输入框
    .user-info-experience-border {
      width: 860px;
      margin: 0 auto;
      margin-top: 30px;

      .user-addeducation-button {
        margin-bottom: 40px;
      }

      .el-textarea {
        width: 600px;
      }

      .timeEditer {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;

        /deep/ .el-date-editor {
          margin-left: unset !important;
          width: unset;
        }

        .checkInDiv {
          margin-left: 20px;
          display: flex;
          align-items: center;

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  //教育经历
  .user-info-education {
    width: 940px;
    background: #ffffff;
    margin-top: 20px;
    padding-bottom: 10px;

    .user-info-education-center {
      width: 860px;
      margin: 0 auto;

      span {
        margin-left: 10px;
        color: #575757;
      }

      //没有工作经验
      .user-info-center-addProject {
        height: 346px;
        width: 100%;
        margin-top: 40px;

        .user-info-center-addProject-center {
          width: 300px;
          margin: 0 auto;
          text-align: center;

          img {
            width: 300px;
            height: 190px;
          }

          span {
            display: block;
            margin-top: 40px;
            font-size: 18px;
            color: #575757;
          }
        }
      }

      .user-info-education-font {
        border-bottom: 1px solid #e4e7ed;
        padding-bottom: 40px;

        .user-info-education-bottom {
          margin-top: 20px;

          strong {
            font-size: 14px;
          }

          span {
            font-size: 12px;
          }
        }
      }

      & .user-info-education-font:last-of-type {
        border-bottom: none;
      }
    }

    .user-info-addeducations {
      margin-top: 30px;

      .el-input {
        width: 250px;
      }

      .el-select {
        width: 250px;
      }

      .timeEditer {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;

        /deep/ .el-date-editor {
          margin-left: unset;
          width: 250px;
        }

        .checkInDiv {
          margin-left: 20px;
          display: flex;
          align-items: center;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .user-addeducation-button {
      padding-bottom: 40px;
    }
  }

  //资格和荣誉
  .user-info-honor {
    margin-top: 20px;
    width: 940px;
    background: #ffffff;
    padding-bottom: 10px;

    .user-info-honor-center {
      width: 860px;
      margin: 0 auto;

      //没有工作经验
      .user-info-center-addProject {
        height: 346px;
        width: 100%;
        margin-top: 40px;

        .user-info-center-addProject-center {
          width: 300px;
          margin: 0 auto;
          text-align: center;

          img {
            width: 300px;
            height: 190px;
          }

          span {
            display: block;
            margin-top: 40px;
            font-size: 18px;
            color: #575757;
          }
        }
      }

      .user-info-honor-font {
        border-bottom: 1px solid #e4e7ed;
        padding-bottom: 40px;

        .user-info-center-bottom {
          margin-top: 20px;

          strong {
            font-size: 14px;
          }

          span {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }

      & .user-info-honor-font:last-of-type {
        border-bottom: none;
      }
    }

    .user-honor-border {
      margin-left: 40px;
      margin-top: 30px;

      /deep/ .el-input {
        width: 400px;
      }

      .el-date-editor {
        margin-left: unset;
        width: 250px;
      }
    }
  }
}

//公用一个头部样式
.user-info-header {
  height: 65px;
  border-bottom: 1px solid #e4e7ed;

  .user-info-header-center {
    width: 860px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-info-header-left {
      line-height: 76px;

      strong {
        font-size: 18px;
        color: #000;
      }

      span {
        font-size: 12px;
        color: #b5b5b5;
        margin-left: 10px;
      }
    }

    .user-info-header-right {
      line-height: 76px;
      color: #00a2e6;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

.user-info-center-header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-addeducation-button {
  text-align: center;

  button {
    width: 140px;
  }

  .button-primary {
    background-color: #00a2e6;
    color: #fff;
    margin-right: 40px;
  }
}

.block {
  display: inline;
}

.el-date-editor {
  margin-left: 20px;

  ::placeholder {
    //color: #b5b5b5 !important;
    font-size: 12px;
  }
}

/deep/ .el-input__inner {
  //height: 36px;
  color: #000;

  &::placeholder {
    //color: #b5b5b5 !important;
    font-size: 12px;
  }
}

/deep/ .el-textarea__inner {
  font-size: 14px;
  color: #000 !important;

  &::placeholder {
    //color: #b5b5b5 !important;
    font-size: 14px;
  }
}

/deep/ .el-date-editor .el-range__icon {
  font-size: 0px;
}

/deep/ .el-form-item__label {
  color: #000;
}

/deep/ .el-checkbox__inner {
  width: 12px;
  height: 12px;
}

/deep/ .el-checkbox__label {
  font-size: 12px;
}

/deep/ .el-icon-arrow-down {
  font-size: 12px;
}

/deep/ .el-dropdown-link {
  font-size: 23px;
  cursor: pointer;
}

.personAtt {
  width: 200px;
  margin-top: 40px;

  .person-att-header {
    width: 200px;
    border-bottom: 1px solid #e4e7ed;
  }
}

/deep/ .el-textarea__inner {
  color: #575757;
  border: 1px solid #b5b5b5;
}

/deep/ .el-form-item__label {
  color: #000;
  font-size: 16px;
}

/deep/ .is-checked {
  color: #000;
}

/* 修改标语 */
/deep/ .el-input--suffix {
  input {
    color: #000;
  }
}

/deep/ .el-form-item__error {
  margin: 0;
}

.user-addeducation-button .button-primary {
  border: unset;
}

.system {
  display: flex;
  align-items: center;
  width: 330px;
}

/deep/ .el-button {
  border-radius: 0;
}

/deep/ .el-textarea .el-input__count {
  background-color: transparent;
}

.view-level {
  .el-dropdown-link {
    font-size: 14px;
    color: #00ace6;
  }
}
</style>

